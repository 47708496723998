.container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
}

/* Allow scrolling in landscape mode */
@media (orientation: landscape) and (max-height: 600px) {
    .container {
        overflow-y: auto;
    }
}
  