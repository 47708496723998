@font-face {
    font-family: 'MuseoSans-500';
    src: url('../assets/fonts/MuseoSans-500.ttf') format('truetype');
  }
  
  .authContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: var(--primary-site-color);
  }
  
  .authContainerView {
    width: 100%;
    height: 258px;
    background: var(--primary-site-color);
    margin-top: 65px;
  }
  
  .nav {
    display: flex;
    justify-content: center;
  }
  
  .nav button {
    margin: 0 10px;
    background: var(--primary-site-color);
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    font-family: 'MuseoSans-500', sans-serif;
    width: 227px;
    height: 50px;
    position: relative;
  }
  
  .nav button::after {
    content: '';
    display: block;
    width: 80%;
    height: 2px;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    bottom: 18%;
    transition: background 0.3s;
    transform: translateX(-50%);
  }
  
  .nav button:hover::after,
  .nav .active::after {
    background: white;
  }
  
  .formWrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-site-color);
    padding-bottom: 20px;
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    padding-left: 46px;
    padding-top: 2px;
  }

  form {
    width: 87%;
  }
  
  .input {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 3px;
    text-align: center;
    background: white;
    outline: none;
    transition: box-shadow 0.2s ease;
  }
  
  .input:focus {
    box-shadow: 0 0 0 2px rgba(100, 204, 201, 0.3);
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
  
  .submitButton {
    font-size: 16px;
    background-color: #64ccc9; /* Cyan background color */
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    width: 80px;
    height: 30px;
  }
  
  .supportText {
    font-size: 16px;
    font-family: 'MuseoSans-500', sans-serif;
    color: white;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4%;
  }
  
  .supportNumber {
    color: #8BB8E8;
    text-decoration: none;
  }

  .passwordRequirements {
    margin: 20px 0;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-size: 14px;
    color: white;
  }

  .requirementsTitle {
    font-weight: 500;
    margin-bottom: 8px;
    color: white;
  }

  .passwordRequirements ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .passwordRequirements li {
    margin: 4px 0;
    padding-left: 20px;
    position: relative;
  }

  .passwordRequirements li:before {
    content: "•";
    position: absolute;
    left: 8px;
  }

  /* Add media query for mobile devices */
  @media (max-width: 768px) {
    .nav {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    
    .nav button {
      font-size: 12px;
      width: auto;
      padding: 0 15px;
    }

    .submitButton {
      height: 42px;
    }
  }

  /* Desktop layout */
  @media (min-width: 1024px) {
    .authContainer {
      width: 50%;
      padding-top: 8%;
    }

    .formContainer {
      padding-left: 10%;
    }
  }
