@font-face {
    font-family: 'SteelfishRg-Regular';
    src: url('../assets/fonts/SteelfishRg-Regular.ttf') format('truetype');
  }
  
  .welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    background-color: var(--primary-site-color);
    padding-left: 0;
  }
  
  .line {
    width: 78%;
    max-width: 78%;
    height: 16px;
    background-color: white;
    margin-bottom:6px;
    margin-top: 10%;
  }
  
  .title {
    font-size: 150px;
    color: white;
    font-family: 'SteelfishRg-Regular', sans-serif;
    text-transform: uppercase;
    line-height: 1.1;
    font-weight: 500;
    margin-top: 0px;
    text-align: center;
  }

  /* Add responsive font size */
  @media (max-width: 768px) {
    .title {
      font-size: 100px;
    }
    .line {
      height: 12px;
    }
  }

  @media (max-width: 480px) {
    .title {
      font-size: 64px;
    }
  }

  /* Desktop layout */
  @media (min-width: 1024px) {
    .welcomeContainer {
      width: 50%;
      padding-left: 5%;
      padding-top: 7%;
    }

    .line {
      margin-left: 0;
    }

    .title {
      text-align: left;
    }
  }
